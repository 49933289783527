import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import {
  getConditionByValue,
  getConditionValueByValue,
  getValueModifierByValue,
  getOperationalModifierByValue,
  getAssignToByValue,
} from '@/utils/workflowHelpers';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import { getNameByEmail } from '@/utils/users';
import Workflow from '@/interfaces/workflows/Workflow';
import workflowApolloClient from '@/lib/appsync/workflow';
import getAllWorkflows from '@/graphql/workflow/queries/getAllWorkflows.graphql';

import getCommentHistory from '@/graphql/workflow/queries/getCommentHistory.graphql';

import getJobResponse from '@/graphql/workflow/queries/getJobResponse.graphql';


import getUserDetails from '@/graphql/workflow/queries/getUserDetails.graphql';
import getWorkflow from '@/graphql/workflow/queries/getWorkflow.graphql';
import postWorkflow from '@/graphql/workflow/mutations/postWorkflow.graphql';
import deleteWorkflow from '@/graphql/workflow/mutations/deleteWorkflow.graphql';
import updateWorkflowQuery from '@/graphql/workflow/mutations/updateWorkflow.graphql';
import accountModule from '@/store/modules/accountModule';

const mapWorkflow = (item) => {
  /* eslint-disable max-len */

  const conditions = [] as any;

  item.Settings.conditions.forEach((c, idx) => {
    let connector = true;
    const recurring = Object.prototype.hasOwnProperty.call(c, 'reoccurring_condition');
    if (item.Settings.conditions.length >= 2 && c.type === 'condition') {
      connector = (item.Settings.conditions[idx - 1] || {}).value === 'AND' ?? false;
    }
    if (c.type === 'condition') {
      conditions.push({
        condition: {
          text: (getConditionByValue(c.condition_group_definition.data_feed) || {}).key,
          value: c.condition_group_definition.data_feed,
        },
        conditionValue: {
          text: (getConditionValueByValue(c.condition_group_definition.source) || {}).text,
          value: c.condition_group_definition.source,
        },
        valueModifier: {
          // eslint-disable-next-line
          text: (getValueModifierByValue(c.condition_group_definition.conditional_operator) || {}).text,
          value: c.condition_group_definition.conditional_operator,
        },
        operationalModifier: {
          // eslint-disable-next-line
          text: (getOperationalModifierByValue(c.condition_group_definition.operational_modifier) || {}).text,
          value: c.condition_group_definition.operational_modifier,
        },
        value: recurring ? c.reoccurring_condition.reoccurring : c.condition_group_definition.value,
        howLong: recurring ? c.reoccurring_condition.duration : c.condition_group_definition.window.split(' hours')[0],
        editable: false,
        secondSectionEditable: false,
        showActions: false,
        connector,
        recurring,
        ...recurring && {
          recurring_data: {
            times: c.reoccurring_condition.reoccurring,
            duration: c.reoccurring_condition.duration,
            durationValue: c.reoccurring_condition.rate,
          },
        },
      });
    }
  });
  let resultToText = item.Settings.assign_to.individual;
  if (item.Settings.assign_to.team || item.Settings.assign_to.role) {
    resultToText = getAssignToByValue(
      item.Settings.assign_to.team || item.Settings.assign_to.role,
    )?.text;
  }

  let triggerText = '';
  conditions.forEach((condition, i) => {
    if ((condition.condition || {}).value === 'STATE_OF_WELL') {
      const wellStateText = condition.recurring
        ? `current well state is <span style="font-weight: 500;">${(condition.conditionValue || {}).text}</span> and has been ${(condition.conditionValue || {}).text}
			   <span style="font-weight: 600;">${(condition.recurring_data || {}).times} time(s)</span> out of
			   <span style="font-weight: 600;">${(condition.recurring_data || {}).duration} ${(condition.recurring_data || {}).durationValue}(s)</span>`
        : `current well state is <span style="font-weight: 500;">${(condition.conditionValue || {}).text}</span>`;
      triggerText += `${i <= 0 ? '' : (condition.connector ? ' and ' : ' or ')} ${wellStateText}`;
    } else {
      // eslint-disable-next-line
      triggerText += `${ i <= 0 ? '' : (condition.connector ? 'and' : 'or') }  <span style="font-weight: 600;">${ condition.conditionValue.text || '' }</span>
	  <span style="font-weight: 600;">${(condition.operationalModifier.text === 'Value' || !condition.operationalModifier.value) ? '' : condition.operationalModifier.text}</span>
          ${condition.valueModifier.text === 'Greater than' || condition.valueModifier.text === 'Less than' ? 'is' : ''}
		  <span style="font-weight: 600;">${condition.valueModifier.text}</span>
		  <span style="font-weight: 600;">${(condition.valueModifier.value === 'DECREASES_AT'
        || condition.valueModifier.value === 'INCREASES_AT') ? `${condition.value}%` : (condition.value || '')}</span> for <span style="font-weight: 600;">${condition.howLong} hours</span>
          `;
    }
  });

  const result = {
    to: {
      text: resultToText,
      value: item.Settings.assign_to.team
        || item.Settings.assign_to.individual
        || item.Settings.assign_to.role,
    },
    assignToType: item.Settings.assign_to.workflow_type,
    source: Array.isArray(item.Settings.well_source) ? item.Settings.well_source : [],
  };

  const workflowText = `If ${triggerText.toLowerCase()} for ${!result.source.length ? 'all wells' : result.source}
        then assign a tasq to <b>${getNameByEmail(result.to.text)}</b>`;

  return {
    ...item,
    active: true,
    conditions,
    result,
    id: item.WorkflowDetailsID,
    createdAt: item.time,
    modifiedAt: item.modification_date,
    triggeredCount: item.WorkflowCount,
    username: getNameByEmail(item.Username),
    workflowText,
  };
  /* eslint-enable */
};

@Module({
  dynamic: true,
  namespaced: true,
  name: 'workflowModule',
  store,
})
class WorkflowModule extends VuexModule {
  workflows: Workflow[] = [];
  wellHistory: any[] = []
  user: User = {} as User;

  wellResponseData: any = null

  activeWorkflow: Workflow|null = null;

  @Mutation
  setWorkflows(data): void {
    this.workflows = data;
  }


  @Mutation
  setWellResponseData(data): void {
    this.wellResponseData = data;
  }



  @Mutation
  setUser(user): void {
	this.user = {
		id: user.user_id,
		username: user.username,
		email: user.username,
		groups:[user.role],
		isAdmin: true,
		phoneNumber: user.user_phone_number,
		firstName: user.first_name,
		lastName: user.last_name
	  };
  }

  @Mutation
  deleteWorkflowMutation(workflow) {
    const foundWorkflowIndex = this.workflows.findIndex(w => w.id === workflow.id);
    if (foundWorkflowIndex !== -1) {
      this.workflows.splice(foundWorkflowIndex, 1);
    }
  }

  @Action
  async getUserProfile({username}): Promise<void> {
    const {
      data: {
        get_user_details: getUserDetailsResult,
      },
    } = await workflowApolloClient.query({
      query: getUserDetails,
      variables: {
		operator: getConfigEnv('OPERATOR_LOWERCASED'),
		username: username
      },
    });

	this.setUser(getUserDetailsResult)
  }

  @Mutation
  setActiveWorkflow(data): void {
    this.activeWorkflow = data;
  }

  @Mutation
  addWorkflowMutation(data): void {
    this.workflows.unshift(data);
  }



  @Mutation
  setWellHistory(data): void {
    this.wellHistory = data;
  }
  
  

  



  @Action
  async getJobResponse(prediction_id): Promise<void> {
	this.setWellResponseData(null)
    const {
      data: {
        get_job_response: {
          Result,
        },
      }
    } = await workflowApolloClient.query({
      query: getJobResponse,
      variables: {
		input: {
			PredictionID: prediction_id,
		}

      },
    });

	var tasq_response_data = JSON.parse(Result)

	const getStepOneCompleted = (tasq: any) => tasq?.ResponseData?.UserResponse?.Selection;
	const getStepTwoCompleted = (tasq: any) => tasq?.ResponseData?.UserResponse?.Selection
	  && tasq?.ResponseData?.UserResponse?.SelectionDetails?.Reason.length;
	const getStepThreeCompleted = (tasq: any) => tasq?.ResponseData?.UserResponse?.Selection
	  && tasq?.ResponseData?.UserResponse?.SelectionDetails?.Reason
	  && tasq?.ResponseData?.UserResponse?.SelectionDetails?.Action;
	const getStepFourCompleted = (tasq: any) => tasq?.ResponseData?.UserResponse?.Selection
	  && tasq?.ResponseData?.UserResponse?.SelectionDetails?.Reason
	  && tasq?.ResponseData?.UserResponse?.SelectionDetails?.Action
	  && tasq?.ResponseData?.UserResponse?.CorrectedStateChangeDate;
	



	if (tasq_response_data != null) {
	    // @ts-ignore
		tasq_response_data.stepOneCompleted = getStepOneCompleted(tasq_response_data);
		// @ts-ignore
		tasq_response_data.stepTwoCompleted = getStepTwoCompleted(tasq_response_data);
		// @ts-ignore
		tasq_response_data.stepThreeCompleted = getStepThreeCompleted(tasq_response_data);
		// @ts-ignore
		tasq_response_data.stepFourCompleted = getStepFourCompleted(tasq_response_data);
		// @ts-ignore
		// tasq_response_data.stepLabel = getStepLabel(tasq_response_data);
		// // @ts-ignore
		// tasq_response_data.dateAgoText = getDateAgoText(tasq_response_data).text;
		// // @ts-ignore
		// tasq_response_data.dateAgoValue = getDateAgoText(tasq_response_data).value;
	}


	this.setWellResponseData(tasq_response_data)

  }












  @Action
  async getWellHistory(node_id): Promise<void> {
	this.setWellHistory([])
    const {
      data: {
        get_well_comment_history: {
          well_history,
        },
      }
    } = await workflowApolloClient.query({
      query: getCommentHistory,
      variables: {
		node_id: node_id,
		operator: getConfigEnv('OPERATOR_LOWERCASED'),
      },
    });


	for (var x = 0; x < well_history.length; x++) {
		try {
			well_history[x] = JSON.parse(well_history[x])
		  }
		  catch(err) {
			well_history[x] = well_history[x]
		  }
		
	}

 	this.setWellHistory(well_history)

  }
















  @Action
  async getActiveWorkflow(workflow): Promise<void> {
    const {
      data: {
        get_workflow: {
          error,
          workflow: backendWorkflow,
          assignment_count: triggeredCount,
          actioned_percentage: actionPerc,
        },
      }
    } = await workflowApolloClient.query({
      query: getWorkflow,
      variables: {
        input: {
          id: workflow.id,
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });

    workflow = this.workflows?.find((i) => i.id === workflow.id) || {};
    if (!error && workflow) {
      workflow = Object.assign(mapWorkflow(JSON.parse(backendWorkflow)), workflow);
      if (!workflow) {
        // commit(ADD_ASSIGNMENT, assignment);
      }
      Object.assign(workflow, {
        triggeredCount,
        actionPerc,
      });

      this.setActiveWorkflow(workflow);
    }
  }


  @Action({
    commit: 'setWorkflows',
  })
  async getWorkflows(): Promise<Workflow[]> {
    try {
      const {
        data:
          {
            get_all_workflows: {
              workflows,
            },
          },
      } = await workflowApolloClient.query({
        query: getAllWorkflows,
        variables: {
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      });

      const items = workflows.map((i) => mapWorkflow(jsonParse(i)));
      items.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      return items;
    } catch (e) {
      console.error(`Error while fetching procedures: ${e}`);
      return [];
    }
  }

  @Action
  async addWorkflow(workflow) {
    try {
      let assignToKey = 'team';
      if (workflow.result.assignToType !== 'TEAM') {
        assignToKey = workflow.result.assignToType === 'ROLE' ? 'role' : 'individual';
      }
      const conditions = [] as any;
      // eslint-disable-next-line
      workflow.conditions.map((cond, index) => {
        const wellStateSelected = (cond.condition || {}).value === 'STATE_OF_WELL';
        if (index >= 1) {
          conditions.push({
            type: 'condition_operator',
            value: cond.connector ? 'AND' : 'OR',
          });
        }
        conditions.push({
          type: 'condition',
          condition_group_definition: {
            data_feed: cond.condition.value,
            source: cond.conditionValue,
            conditional_operator: wellStateSelected ? '' : cond.valueModifier,
            operational_modifier: wellStateSelected // eslint-disable-line
              ? '' : (cond.operationalModifier === 'VALUE' ? '' : cond.operationalModifier),
            value: wellStateSelected ? '' : cond.value,
            window: wellStateSelected ? '' : `${cond.howLong} ${cond.windowValue || 'hours'}`,
          },
          ...cond.recurring && wellStateSelected && {
            reoccurring_condition: {
              reoccurring: cond.value,
              duration: cond.howLong,
              rate: 'DAY',
            },
          },
        });
      });

      const workflowToSave = {
        version: '1.0',
        well_source: workflow.result.source.length ? workflow.result.source : 'ALL',
        conditions,
        assign_to: {
          workflow_type: workflow.result.assignToType,
          [assignToKey]: workflow.result.to,
        },
      };

      await workflowApolloClient.mutate({
        mutation: postWorkflow,
        variables: {
          input: {
            settings: JSON.stringify(workflowToSave),
            username: accountModule.user.email,
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      });
      return this.getWorkflows();
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async updateWorkflow(workflow) {
    try {
      let assignToKey = 'team';
      if (workflow.result.assignToType !== 'TEAM') {
        assignToKey = workflow.result.assignToType === 'ROLE' ? 'role' : 'individual';
      }
      const conditions = [] as any;
      // eslint-disable-next-line
      workflow.conditions.map((cond, index) => {
        const wellStateSelected = (cond.condition || {}).value === 'STATE_OF_WELL';
        if (index >= 1) {
          conditions.push({
            type: 'condition_operator',
            value: cond.connector ? 'AND' : 'OR',
          });
        }
        conditions.push({
          type: 'condition',
          condition_group_definition: {
            data_feed: cond.condition.value,
            source: cond.conditionValue,
            conditional_operator: wellStateSelected ? '' : cond.valueModifier,
            operational_modifier: wellStateSelected // eslint-disable-line
              ? '' : (cond.operationalModifier === 'VALUE' ? '' : cond.operationalModifier),
            value: wellStateSelected ? '' : cond.value,
            window: wellStateSelected ? '' : `${cond.howLong} ${cond.windowValue || 'hours'}`,
          },
          ...cond.recurring && wellStateSelected && {
            reoccurring_condition: {
              reoccurring: cond.value,
              duration: cond.howLong,
              rate: 'DAY',
            },
          },
        });
      });

      const workflowToSave = {
        version: '1.0',
        well_source: workflow.result.source.length ? workflow.result.source : 'ALL',
        conditions,
        assign_to: {
          workflow_type: workflow.result.assignToType,
          [assignToKey]: workflow.result.to,
        },
      };
      await workflowApolloClient.mutate({
        mutation: updateWorkflowQuery,
        variables: {
          input: {
            id: workflow.id,
            username: accountModule.user.email,
            settings: JSON.stringify(workflowToSave),
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      });
      await this.getWorkflows();
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async deleteWorkflow(workflow) {
    try {
      await workflowApolloClient.mutate({
        mutation: deleteWorkflow,
        variables: {
          input: {
            id: workflow.id,
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      });
      this.deleteWorkflowMutation(workflow);
    } catch (e) {
      throw new Error(e);
    }
  }
}

export default getModule(WorkflowModule);
