import { getConfigEnv } from '@/utils/helpers';

const getConditionByValue = (value) => [
  {
    id: 1,
    key: 'Signal',
    value: 'SIGNAL',
  },
  {
    id: 2,
    key: 'Production',
    value: 'PRODUCTION',
  },
  {
    id: 3,
    key: 'Setpoint',
    value: 'SETPOINT',
  },
  {
    id: 4,
    key: 'Event',
    value: 'EVENT',
  },
  {
    id: 5,
    key: 'State of well',
    value: 'STATE_OF_WELL',
  },
  {
    id: 6,
    key: 'End of Tubing from perfs',
    value: 'EOT',
  },
  {
    id: 7,
    key: 'Downhole components',
    value: 'DOWNHOLE_COMPONENTS',
  },
].find((i) => i.value === value);

const getConditionValueByValue = (value) => [
  ...getConfigEnv('WORKFLOW_SIGNALS_ARRAY').map((i) => ({
    text: i.name,
    value: i.name.replace(/ /g, '_').toUpperCase(),
  })),
  ...[
    {
      text: 'Oil production',
      value: 'OIL_PRODUCTION',
    },
    {
      text: 'Gas flowrate',
      value: 'GAS_FLOWRATE',
    },
    {
      text: 'Water production',
      value: 'WATER_PRODUCTION',
    },
  ],
  ...[
    {
      text: 'Injection pressure',
      value: 'INJECTION_PRESSURE',
    },
    {
      text: 'Static pressure',
      value: 'STATIC_PRESSURE',
    },
    {
      text: 'Casing pressure',
      value: 'CASING_PRESSURE',
    },
    {
      text: 'Water static pressure',
      value: 'WATER_STATIC_PRESSURE',
    },
  ],
  ...[
    {
      text: 'Well shut button',
      value: 'WELL_SHUT_BUTTON',
    },
    {
      text: 'Valve status',
      value: 'VALVE_STATUS',
    },
    {
      text: 'Arrival timer setpoint',
      value: 'ARRIVAL_TIMER_SETPOINT',
    },
    {
      text: 'Tubing pressure setpoint',
      value: 'TUBING_PRESSURE_SETPOINT',
    },
    {
      text: 'Plunger last runtime',
      value: 'PLUNGER_LAST_RUNTIME',
    },
    {
      text: 'Max flowrate',
      value: 'MAX_FLOWRATE',
    },
    {
      text: 'Min flowrate',
      value: 'MIN_FLOWRATE',
    },
    {
      text: 'Well state',
      value: 'WELL_STATE',
    },
    {
      text: 'Fillage control setpoint',
      value: 'FILLAGE_CONTROL_SETPOINT',
    },
    {
      text: 'Plunger fall delay mode',
      value: 'PLUNGER_FALL_DELAY_MODE',
    },
    {
      text: 'Tubing - line setpoint',
      value: 'TUBING_LINE_SETPOINT',
    },
    {
      text: 'Time in state',
      value: 'TIME_IN_STATE',
    },
    {
      text: 'Plunger afterflow mode',
      value: 'PLUNGER_AFTERFLOW_MODE',
    },
    {
      text: 'Flowrate setpoint',
      value: 'FLOWRATE_SETPOINT',
    },
    {
      text: 'Plunger failure setpoint',
      value: 'PLUNGER_FAILURE_SETPOINT',
    },
    {
      text: 'Low flow setpoint',
      value: 'LOW_FLOW_SETPOINT',
    },
    {
      text: 'Plunger well status',
      value: 'PLUNGER_WELL_STATUS',
    },
  ],
  ...[
    {
      text: 'Hi Load',
      value: 'HI_LOAD',
    },
    {
      text: 'Low Load',
      value: 'LO_LOAD',
    },
    {
      text: 'Malfunction setpoint',
      value: 'MALFUNCTION_SETPOINT',
    },
  ],
  ...[
    {
      text: 'Prediction',
      value: 'PREDICTION',
    },
    {
      text: 'Anomaly detection',
      value: 'ANOMALY_DETECTION',
    },
    {
      text: 'Down',
      value: 'DOWN',
    },
    {
      text: 'Failure',
      value: 'FAILURE',
    },
  ],
].find((i) => i.value === value);

const getValueModifierByValue = (value) => [
  {
    text: 'Greater than',
    value: 'GREATER_THAN',
  },
  {
    text: 'Less than',
    value: 'LESS_THAN',
  },
  {
    text: 'Decreases at',
    value: 'DECREASES_AT',
  },
  {
    text: 'Increases at',
    value: 'INCREASES_AT',
  },
].find((i) => i.value === value);

const getOperationalModifierByValue = (value) => [
  {
    text: 'Value',
    value: 'VALUE',
  },
  {
    text: 'STD dev',
    value: 'STD_DEV',
  },
  {
    text: 'Mean',
    value: 'MEAN',
  },
  {
    text: 'Times',
    value: 'TIMES',
  },
].find((i) => i.value === value);

const getAssignToByValue = (value) => [
  {
    text: 'Engineer',
    value: 'Engineers',
  },
  {
    text: 'Automation',
    value: 'Automation',
  },
  {
    text: 'Field Operator',
    value: 'FieldOperator',
  },
  {
    text: 'Optimizer',
    value: 'Optimizer',
  },
  {
    text: 'Team 1',
    value: 'Team_1',
  },
  {
    text: 'Team 2',
    value: 'Team_2',
  },
].find((i) => i.value === value);

export {
  getConditionByValue,
  getConditionValueByValue,
  getValueModifierByValue,
  getOperationalModifierByValue,
  getAssignToByValue,
};
